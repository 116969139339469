import cn from 'classnames';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Account } from './Account';
import { Customers } from './Customers';
import { Prototype } from './Prototype';
import { Faq } from './Faq';
import { Help } from './Help';
import { Home } from './Home';
import { Manage } from './Manage';
import { Manufacturing } from './Manufacturing';
import { Methodology } from './Methodology';
import { Navigation } from './Navigation';
import { Products } from './Products';
import { Workspace } from './Workspace';
import { LimitsProvider } from '../../hooks/useLimits';
import { ListsProvider } from '../../hooks/useLists';
import { ProfileProvider } from '../../hooks/useProfile';
import { Dashboard } from './Dashboard';
import { NewBaseline } from './Manufacturing/Baseline/NewBaseline';
import { NewHistorical } from './Manufacturing/Historicals/NewHistorical';
import { NewForecast } from './Manufacturing/Forecasts/NewForecast';
import { Workspaces } from './Workspaces';
import { NotFound } from './NotFound';
import { Forbidden } from './Forbidden';

export const AppRoutes = (id1?: string, id2?: string) => ({
  forbidden: '/forbidden',
  notFound: '/not-found',
  products: '/products',
  report: {
    historicalList: '/manufacturing/historical-reports',
    newHistorical: '/manufacturing/historical-reports/new',
    historicalOverview: `/manufacturing/historical-reports/${id1}/report/production/overview`,
    newBaseline: '/manufacturing/baseline/new',
    newForecast: '/manufacturing/forecasts/new',
    forecastOverview: `/manufacturing/forecasts/${id1}/report/production/overview`,
    baselineOverview: `/manufacturing/baseline/production/overview`,
    productOverview: `/products/${id1}/report/production/overview`,
    modelOverview: `/products/${id1}/models/${id2}/report/overview`,
  },
});

export const Main = () => {
  const location = useLocation();

  return (
    <LimitsProvider>
      <ListsProvider>
        <ProfileProvider>
          <div
            className={cn('w-full flex flex-col justify-between items-center min-h-screen', {
              'bg-slate-50': ['/dashboard', '/prototype'].includes(location.pathname),
              'gap-10': !['/dashboard', '/prototype'].includes(location.pathname),
            })}
          >
            <Navigation />
            <div className='w-full h-full max-w-screen-xl px-6'>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/customers/*' element={<Customers />} />
                <Route path='/methodology/*' element={<Methodology />} />
                <Route path='/faq/*' element={<Faq />} />
                <Route path='/help/*' element={<Help />} />
                <Route path='/account/*' element={<Account />} />
                <Route path='/workspaces/*' element={<Workspaces />} />
                <Route path='/workspace/*' element={<Workspace />} />
                <Route path='/prototype/*' element={<Prototype />} />
                <Route path='/dashboard/*' element={<Dashboard />} />
                <Route path='/products/*' element={<Products />} />
                <Route path={AppRoutes().report.newForecast} element={<NewForecast />} />
                <Route path={AppRoutes().report.newHistorical} element={<NewHistorical />} />
                <Route path={AppRoutes().report.newBaseline} element={<NewBaseline />} />
                <Route path='/manufacturing/*' element={<Manufacturing />} />
                <Route path='/manage/*' element={<Manage />} />
                <Route path='/not-found/*' element={<NotFound />} />
                <Route path='/forbidden' element={<Forbidden />} />
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
            </div>
          </div>
        </ProfileProvider>
      </ListsProvider>
    </LimitsProvider>
  );
};
