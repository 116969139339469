import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  FacilityType,
  FinalDestinationNode,
  NodeType,
  ProductState,
  ProductType,
  ProductV3,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  data?: FinalDestinationNode;
  onSave: ModalFormSaveCallback<FinalDestinationNode>;
  onOpenChange?: (open: boolean) => void;
}>;

export const FinalDestinationDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm
      size='narrow'
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add final destination'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>This is the final destination of your product, where it gets handed over to your customer.</div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.FinalDestination,
        flagged: false,
        edges: new Array<string>(),
        facility: undefined as any as Facility,
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: yup.object().required(),
      })}
      entityName='final destination'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={props.onSave}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<FinalDestinationNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-6'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Select a facility</div>
          <div className='w-2/3'>
            <TaggableField name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ type }) =>
                            props.productFormik.values.productType !== ProductType.Intermediate ||
                            [FacilityType.Production, FacilityType.Warehouse, FacilityType.Store].includes(type),
                        ),
                      ),
                    );
                  }}
                  renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                  menuFooter={
                    !newFacilityForm && (
                      <SelectFooterAddButton onClick={() => setNewFacilityForm(true)} name={newFacilityName} label='facility' />
                    )
                  }
                />
              )}
            </TaggableField>
          </div>
        </div>
        {newFacilityForm && (
          <NewFacilityForm
            name={newFacilityName}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', newFacility);
              setNewFacilityForm(false);
            }}
          />
        )}
      </div>
    </TaggableFieldsContainer>
  );
};
